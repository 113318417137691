html {
    --app-primary-color : #01bf71;
    --app-secondary-color : #fff;
  }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
  /* color: var(--app-primary-color); */
}

mark {
  background-color: #01bf71;
}

.italic-font {
  font-style: italic;
  font-weight: bold;
}

.openlinks {
  color: #01bf71;
  cursor: pointer;
}

.copylinks {
  padding-left: 10px;
  color: darkred;
}
.Toastify__toast--success {
  background: var(--app-primary-color) !important;
}

a{
  text-decoration: none;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.dockdevelopers-product-link {
  margin-top: 10px;
  color: #01bf71;
  font-size: 12px;
  font-weight: 600;
}

.client-comment {
  padding: 16px 16px; 
  margin-bottom: 32px; 
  border: none; 
  border-radius: 4px;
}

.rating-container {
  display : flex;
  margin-bottom: 32px;
}

.bottom-2-margin {
  margin-bottom: 2px !important;
}

.bottom-8-margin {
  margin-bottom: 8px !important;
}

.bottom-10-margin {
  margin-bottom: 10px !important;
}

.bottom-13-margin {
  margin-bottom: 13px !important;
}

.rating-container a {
    margin: auto;
    border-radius: 52%;
    border: 2px solid white;
    padding: 10px;
    width: 45px;
    height: 45px;
    text-align: center;
    color: white;
}

.rating-container a.is-active {
  background-color: #01bf71;
  border-color: black;
}

.love-heart {
  animation: textgrowth 1s infinite alternate;
}
@keyframes textgrowth {
  0% {
  font-size: 15px;
  }
  100% {
  font-size: 22px;
  }
}

.waiting-for-yes {
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: cursive;
}

.its-a-yes {
  font-family: cursive;
}

.coupon-container input {
  width: 60%;
}

.applyCouponBtn {
  padding: 8px;
  background: #01bf71;
  border: 1px solid #01bf71;
  color: white;
  padding-bottom: 16px;
  padding-top: 14px;
  border-radius: 4px;
  margin-left: -3px;
  cursor: pointer;
}

.couponDesc {
  color: darksalmon !important;
}

.aboutPara {
  margin:5% 10% 8% 10% !important;
  font-size: 14px !important;
  color: white !important;
}

.primaryText {
  color: #01bf71;
}

/* .confetti-explosion-screen-0-2-219 > div {
    top: 100px !important;
} */

div[class^='confetti-explosion-screen'] > div {
  top: 100px !important;
}